import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, Observable } from 'rxjs';
import { RatesApiService } from '../api/rates.api.service';
import {
  AvailableVehicleInterface,
  ErrorInterface,
  ErrorResponseInterface,
  RatesInterface, RequestInterface,
} from '../interfaces/rates.interfaces';
import { SearchRatesDTO } from '../interfaces/search-form.interfaces';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class RatesDataService {
  private readonly _request$ = new BehaviorSubject<RequestInterface | null>(null);
  private readonly _rates$ = new BehaviorSubject<AvailableVehicleInterface[]>([]);
  private readonly _bookingDays$ = new BehaviorSubject<number>(0);
  private readonly _currency$ = new BehaviorSubject<string>('EUR');
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(private ratesApiService: RatesApiService, private toastService: ToastService) {}

  public get rates$(): Observable<AvailableVehicleInterface[]> {
    return this._rates$.asObservable();
  }

  public get request$(): Observable<RequestInterface | null> {
    return this._request$.asObservable();
  }

  public get bookingDays$(): Observable<number> {
    return this._bookingDays$.asObservable();
  }

  public get currency$(): Observable<string> {
    return this._currency$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public loadRates(options: SearchRatesDTO): void {
    this._isLoading$.next(true);
    this.ratesApiService.getRates(options).pipe(
      finalize(() => this._isLoading$.next(false)),
      catchError(() => {
        this._rates$.next([]);
        return [];
      })
    )
      .subscribe((rates: RatesInterface | ErrorResponseInterface) => {
        if ('errors' in rates) {
          this.handleRatesErrors(rates)
        } else  {
          this._rates$.next(rates.available_vehicles);
          this._bookingDays$.next(rates.request.rental_days);
          this._currency$.next(options.currency);
        }
      });
  }

  public loadRateForQuotation(quotation: string, currency: string = 'EUR'): void {
    this._isLoading$.next(true);

    this.ratesApiService.getRate(quotation, currency).pipe(
      finalize(() => this._isLoading$.next(false)),
      catchError(() => {
        this._rates$.next([]);
        return [];
      })
    )
      .subscribe((rates: RatesInterface | ErrorResponseInterface) => {
        if ('errors' in rates) {
          this.handleRatesErrors(rates)
        } else  {
          this._request$.next(rates.request);
          this._rates$.next(rates.available_vehicles);
          this._bookingDays$.next(rates.request.rental_days);
          this._currency$.next(currency);
        }
      });
  }

  public resetRates(): void {
    this._rates$.next([]);
    this._isLoading$.next(false);
  }

  private handleRatesErrors(rates: ErrorResponseInterface): void {
    if (Array.isArray(rates.errors)) {
      rates.errors.forEach((error: ErrorInterface) => {
        this.toastService.danger(error.short_text);
      });
    } else {
      this.toastService.danger(rates.errors.short_text);
    }

    this.resetRates();
  }
}
