import {Injectable} from '@angular/core';
import {AvailableVehicleInterface} from 'src/app/core/interfaces/rates.interfaces';
import {ReservationInterface} from 'src/app/core/interfaces/reservations.interfaces';

@Injectable({
  providedIn: 'any',
})
export class CarCalculationsService {
  _selectedCar!: ReservationInterface | AvailableVehicleInterface;

  set selectedCar(car: ReservationInterface | AvailableVehicleInterface) {
    this._selectedCar = car;
  }

  get selectedCar() {
    return this._selectedCar;
  }

  getPrepaymentPercentage(): number {
    return +this.selectedCar.rate_adjustment / 100;
  }

  getVehicleRentPriceWithFee(bookingDays: number): number {
    return this.getPricePerDay() * bookingDays;
  }

  getPricePerDay(): number {
    const chargeItem = this.selectedCar.rental_rate.vehicle_charges.find((e) => e.ota_code === 1);
    const amount = +(chargeItem?.amount ?? 0);
    const vat = chargeItem?.tax_inclusive == "false" ? +(chargeItem?.tax_amount.total ?? 0) : 0;
    return amount + vat;
  }

  getDeskPaymentAmount(equipmentPrice: number, bookingDays: number): number {
    return this.getTotalAmount(equipmentPrice, bookingDays) - this.getBookingFeeAmount(equipmentPrice, bookingDays);
  }

  getBookingFeeAmount(equipmentPrice: number, bookingDays: number): number {
    return this.getTotalAmount(equipmentPrice, bookingDays) * this.getPrepaymentPercentage();
  }

  getTotalAmount(equipmentPrice: number, bookingDays: number): number {
    return equipmentPrice + +this.getVehicleRentPriceWithFee(bookingDays);
  }
}
