import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {filter, finalize, map, Observable, take} from 'rxjs';
import {SearchResultsDataService} from '../../core/services/search-results-data.service';
import {AvailableVehicleInterface, RequestInterface} from "../../core/interfaces/rates.interfaces";
import {RatesDataService} from "../../core/services/rates-data.service";
import {UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";
import {DesksDataService} from "../../core/services/desks-data.service";
import {DEFAULT_AGE} from "../../home/home.component";

@Injectable({
  providedIn: 'root',
})
export class BookingConfirmationGuard implements CanActivate {

  desks$ = this.desksDataService.desks$;

  form = this.fb.group({
    pickUpLocation: new UntypedFormControl(null, Validators.required),
    pickUpDate: new UntypedFormControl(null, Validators.required),
    pickUpTime: new UntypedFormControl(null, Validators.required),
    dropOffLocation: new UntypedFormControl(null, Validators.required),
    dropOffDate: new UntypedFormControl(null, Validators.required),
    dropOffTime: new UntypedFormControl(null, Validators.required),
    driversAge: new UntypedFormControl(null, [Validators.required, Validators.min(18), Validators.max(99)]),
    nationality: [null],
    promocode: [null],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private desksDataService: DesksDataService,
    private ratesDataService: RatesDataService,
    private searchResultsDataService: SearchResultsDataService,
    private router: Router
  ) {
    this.desksDataService.loadDesks();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const quotation = route.queryParamMap.get('q');
    const currency = route.queryParamMap.get('c') ?? 'EUR';

    if (quotation !== null) {
      // localhost:4200/booking/confirmation?q=6129b97a-b97e-41aa-81d0-0db1721ec843
      this.ratesDataService.loadRateForQuotation(quotation, currency);
      this.ratesDataService.rates$.subscribe((res: AvailableVehicleInterface[]) => {
        this.searchResultsDataService.saveSelectedCar(res[0]);
      });

      let request: RequestInterface;
      this.ratesDataService.request$
        .pipe(
          filter(req => req !== null),
          take(1),
          finalize(() => {
            this.desks$
              .pipe(
                filter(desks => desks.length > 0),
                take(1),
                finalize(() => {
                  this.searchResultsDataService.saveSearchForm(this.form.getRawValue());
                }),
                map(desks => desks.filter(desk => (desk.code === request.pickup_desk_code || desk.code === request.return_desk_code))),
              )
              .subscribe(desks => {
                if (request.pickup_desk_code === request.return_desk_code)
                {
                  this.form.controls['pickUpLocation'].setValue(desks[0]);
                  this.form.controls['dropOffLocation'].setValue(desks[0]);
                }
                else
                {
                  this.form.controls['pickUpLocation'].setValue(desks.find(desk => desk.code === request.pickup_desk_code));
                  this.form.controls['dropOffLocation'].setValue(desks.find(desk => desk.code === request.return_desk_code));
                }
              });
          }),
        )
        .subscribe((req: RequestInterface | null) => {
          if (req) {
            request = req;
            let pickUpDate = new Date(req.pickup_datetime);
            let dropOffDate = new Date(req.return_datetime);

            this.form.controls['pickUpDate'].setValue(pickUpDate);
            this.form.controls['pickUpTime'].setValue(pickUpDate);
            this.form.controls['dropOffDate'].setValue(dropOffDate);
            this.form.controls['dropOffTime'].setValue(dropOffDate);
            this.form.controls['driversAge'].setValue(DEFAULT_AGE);
          }
        });

      return true;
    }

    return this.searchResultsDataService.isCarSelected$.pipe(
      map((res: boolean) => {
        if (res) {
          return true;
        } else {
          this.router.navigate([this.router.url.split('?')[0]]);
          return false;
        }
      }),
    );
  }

}
