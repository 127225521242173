import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, finalize, map, Observable, of } from 'rxjs';
import { OrderApiService } from '../api/order.api.service';
import { ReservationsApiService } from '../api/reservations.api.service';
import { ErrorInterface, ErrorResponseInterface } from '../interfaces/rates.interfaces';
import { ReservationDTO, ReservationInterface } from '../interfaces/reservations.interfaces';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationDataService {
  private readonly _reservations$ = new BehaviorSubject<ReservationInterface | null>(null);
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private reservationsApiService: ReservationsApiService,
    private orderApiService: OrderApiService,
    private toastService: ToastService,
    private router: Router
  ) {}

  public get reservations$(): Observable<ReservationInterface | null> {
    return this._reservations$.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public makeReservation(reservation: ReservationDTO): void {
    this._isLoading$.next(true);

    this.reservationsApiService.makeReservation(reservation).pipe(
      finalize(() => this._isLoading$.next(false)),
      map(({ redirect_to }) => {
        window.location.href = redirect_to;
      }),
      catchError(() => {
        this.toastService.danger('Something went wrong! Try again');
        return of(null)
      }),
    ).subscribe()
  }

  public clearReservation(): void {
    this._reservations$.next(null);
    this._isLoading$.next(false);
  }

  public validatePayment(id: number, hash: string): void {
    this.orderApiService.validatePayment(id, hash).pipe(
      map((reservation: ReservationInterface | ErrorResponseInterface) => {
        if ('errors' in reservation) {
          this.handlePaymentErrors(reservation);
          this._reservations$.next(null)
        } else {
          this._reservations$.next(reservation)
        }
      }),
      catchError(() => {
        this.toastService.danger('Something went wrong! Try again');
        return of(null)
      }),
    ).subscribe();
  }

  private handlePaymentErrors(reservation: ErrorResponseInterface): void {
    if (Array.isArray(reservation.errors)) {
      reservation.errors.forEach((error: ErrorInterface) => {
        this.toastService.danger(error.short_text);
      });
    } else {
      this.toastService.danger(reservation.errors.short_text);
    }
    this.router.navigateByUrl('/');
  }
}
