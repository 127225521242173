<div class="booking-voucher" *ngIf="!(reservationLoading$ | async); else loader">

  <div class="logos">
    <img src="../../../assets/images/logo-right-blue.svg" alt="Car-Bro">

    <img [src]="fleetLogoUrl$ | async" alt="Fleet logo">
  </div>

  <div class="printable" *ngIf="reservation$ | async as reservation" #voucher>

    <div class="heading">
      Your rental voucher
    </div>

    <div class="voucher">

      <div class="voucher-section">
        <div class="voucher-section-title">BOOKING DATE</div>
        <div class="voucher-section-paragraph big">{{ reservationDate | date : 'dd/MM/yyyy' }}</div>
      </div>

      <div class="voucher-section">
        <div class="voucher-section-title">RENTAL VOUCHER No.</div>
        <div class="voucher-section-paragraph big">{{ reservation.reservation_number }}</div>
      </div>

      <div class="voucher-section">
        <div class="voucher-section-paragraph big">
          {{ reservation.customers.primary_driver.first_name }}
          {{ reservation.customers.primary_driver.last_name }}
        </div>
        <div class="voucher-section-paragraph medium">{{ reservation.customers.primary_driver.email }}</div>
        <div class="voucher-section-paragraph medium">{{ reservation.customers.primary_driver.telephone_number }}</div>
      </div>

      <div class="voucher-section"
           *ngIf="reservation.veh_segment_info.location_details[0]?.additional_info?.veh_rent_loc_info">
        <div class="voucher-section-title">Collection information</div>
        <div class="voucher-section-paragraph medium">
          PLEASE COLLECT YOUR CAR FROM <br>
          {{ reservation.veh_segment_info.location_details[0].additional_info.veh_rent_loc_info }}
        </div>
      </div>

      <div class="voucher-section">
        <div class="voucher-section-title">BOOKING DETAILS</div>
        <div class="voucher-section-paragraph medium">
          <span class="uppercase">BOOKING No: </span>
          {{ reservation.reservation_number }}
        </div>

        <div class="voucher-section-paragraph medium">
          <span class="uppercase">CAR:</span>
          {{ reservation.vehicle.make_model }}
        </div>
        <div class="voucher-section-paragraph medium">
          <span class="uppercase">PICK UP:  </span>
          {{ reservation.veh_segment_info.location_details[0].address.country }},
          {{ reservation.veh_segment_info.location_details[0].name }},
          {{ reservation.veh_rental_core.pickup_datetime | date: 'dd/MM/yyyy' }}
        </div>
        <div class="voucher-section-paragraph medium">
          <span class="uppercase">DROP OFF: </span>
          {{ reservation.veh_segment_info.location_details[1].address.country }},
          {{ reservation.veh_segment_info.location_details[1].name }},
          {{ reservation.veh_rental_core.return_datetime | date: 'dd/MM/yyyy' }}
        </div>
      </div>

      <div class="voucher-section dashed">

        <div class="voucher-section-paragraph medium">
          Booking fee: <br>
          {{ carCalculationsService.getTotalAmount((equipmentTotal$ | async)!, reservation.rental_days) | number: '1.2-2' }} EUR
          <br>
          (PAID <span class="invoice-download" (click)="downloadInvoice()">download invoice</span>)
        </div>
        <div class="voucher-section-paragraph medium">
          Car rental fee: <br>
          {{ +reservation.total_charge.rate_total_amount | number: '1.2-2' }} EUR
        </div>
        <div class="voucher-section-paragraph medium" *ngIf="(equipmentTotal$ | async)! > 0">
          Accessories: <br>
          {{ getEquipmentString(reservation.equipment) }} <br>
          {{ equipmentTotal$ | async | number: '1.2-2' }} EUR
        </div>

      </div>

      <div class="voucher-section dashed">

        <div class="voucher-section-paragraph big uppercase">
          TOTAL:
          {{ carCalculationsService.getTotalAmount((equipmentTotal$ | async)!, reservation.rental_days) | number: '1.2-2' }} EUR
        </div>

        <div class="voucher-section-paragraph big">
          Payment on arrival in desk: <br>
          {{ carCalculationsService.getDeskPaymentAmount((equipmentTotal$ | async)!, reservation.rental_days) | number: '1.2-2' }}
          EUR
        </div>

      </div>

    </div>
  </div>

  <div class="actions">

    <div class="right">
      <button shape="round" nbButton (click)="savePdf(true)">Print</button>
      <button shape="round" nbButton (click)="savePdf()">Save</button>
    </div>

  </div>

</div>

<ng-template #loader>
  <div class="loader-wrapper">
    <div class="loader" [nbSpinner]="true" nbSpinnerSize="giant"></div>
  </div>
</ng-template>
